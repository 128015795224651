.faq {
    background: #f5eeee;
}

h1 {
    margin-bottom: 20px;
}

#faq_header {
    text-align: center;
}