.navbar {
    position: fixed;
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    padding: .5rem 4rem;
    padding-left: 1rem;
    background-color: #fff;
    color: black;
    z-index: 40;
  }
  
  .brand-name {
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: bolder;
    font-size: 1.3rem;
    margin-left: 1rem;
    width: 100%;
  }

  .brand-name:hover {
    color: grey;
  }
  
  .navbar-menu {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
    width: 100%;
  }
  
  .navbar-menu ul {
    display: flex;
    column-gap: 1rem;
    padding: 0;
  }
  
  .navbar-menu li {
    list-style-type: none;
    margin: 0 1rem;
    display: flex;
  }

  .navbar-menu .icon_menus {
    display: flex;
    column-gap: 1rem;
  }

  /* .navbar-menu .icon_menu_item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem;
    column-gap: .4rem;
    border-bottom: 2px solid #4ecdc4;
    padding-bottom: .5rem;
  }

  .navbar-menu .icon_menu_item:hover {
    background-color: #4ecdc4;
    cursor: pointer;
    color: white;
  } */
  
  .navbar-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    color:black;
  } 

  .navbar-menu li a:hover {
    color: grey;
    cursor: pointer;
  }
  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #4ecdc4;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  
  .hamburger:hover {
    background-color: #37bbb1;
  }
  
  @media screen and (max-width: 550px) {
    .hamburger {
      display: block;
    }
    
    .navbar-menu .icon_menu_item {
        display: flex;
        align-items: center;
        bottom: 0;
        column-gap: 0.4rem;
        border-bottom: 2px solid #4ecdc4;
        padding-bottom: .5rem;
    }

    .navbar-menu ul {
      flex-direction: column;
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      height: calc(100vh);
      background-color: white;
      border-top: 2px solid rgba(34, 34, 34, 0.3);
      display: none;
    }

    .navbar-menu .icon_menus {
        position: absolute;
        top: 80vh;
        width: 100%;
        right: 0;
        left:0;
        margin-left: .5rem;
        column-gap: 0;
        /* bottom: 0; */
        display: none;
    }
    
    .navbar-menu .icon_menu_item {
        display: flex;
        align-items: center;
        bottom: 0;
        column-gap: 0.4rem;
        border-bottom: 0px;
        background-color: #37bbb1;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .navbar-menu li {
      text-align: center;
      margin: 0;
    }
  
    .navbar-menu li a {
      color: black;
      width: 100%;
      padding: 1.5rem 0;
    }
  
    .navbar-menu li:hover {
      background-color: #eee;
      cursor: pointer;
    }
  
    .navbar-menu.expanded ul {
      display: flex;
      z-index: 10;
    }
    .navbar-menu.expanded .icon_menus {
        display: flex;
        z-index: 10;
    }
  }
  