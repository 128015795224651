.item_container {
    border-bottom: solid;
    border-width: 1px;
    border-color: rgba(128, 128, 128, 0.365);
    cursor: pointer;
}
.item_container:last-child{
    border: none;
}

.question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 15px;
    background: #f5eeee;
}

.question button {
    margin-left: 20px;
    padding: 10px 20px;
    position: relative;
    cursor: pointer;
}

.answer_shown {
    display:flex;
    background:#f5eeee;
    padding-left:  16px;
    padding-right: 16px;
    padding-bottom: 10px;
    font-size: 16px;
    max-width: 820px;
}

.answer_hidden {
    display:none;
}