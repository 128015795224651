.container {
  justify-content: center;
  display: flex;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .modal {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    text-align: center;
    top: 20vh;
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    z-index: 30;
  }

  .modalbutton {

    margin-top: 10px;
    width: 100px;
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 16px 28px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
  }

  .modalbutton:hover {
    background-color: #058757;
  }

