.landing__container {
    width: 100%;
    height: 100vh;
    background-image: url('/src/images/snowblow_bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.landing__content {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing__content h1 {
    margin-top: 60px;
    font-size: 4rem;
    text-align: center;
    color: #ffffff;
    text-align: center;
}

.getquote {
    color: black;
    background: #f2f2f2;
    border-radius: 20px;
    font-weight: 500;
    text-decoration:none;
    font-size: 20px;
    padding: 0px 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.getquote:hover{
    color: grey;
    cursor: pointer;
}

.title {
    padding: 3rem 0rem;
    margin: 0rem 25rem;
    display: flex;
    justify-content: center;
    max-height: auto;
    top: 15rem;
}

.video {
    width: 750px;
    height: 450px;
    position: relative;
    margin-top: 5rem;
}

iframe {
    border: 1px solid black;
}

@media screen and (max-width: 755px) {
    .video {
        width: 350px;
        height: 350px;
    }

    .landing__content h1 {
            font-size: 40px;
        
    }

}