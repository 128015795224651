.quote__container {
    background-color: #f5eeee;;
    width: 100%;
    height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    row-gap: .4rem;
    column-gap: .4rem;
}


.quote__container label {
    display: block;
    text-align: left;
    line-height: 26px;
    padding: 0 20px;
    margin-bottom: 25px;
}

.quote__container form {
    justify-content: center;
    align-items: center;
}

.quote__container input {
    display: block;
    padding-left: 3px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 35px;
    width: 300px;
    border: none;
    border-radius: .5rem;
}

.quote__container input:focus{
    outline: none;
    border: 2px solid #4ecdc4;
}


span {
    color: red;
}

.error {
    color: red;
    margin-left: 20px;
    font-weight: bold;
}

.quote__container__row {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width:700px) {
    .quote__container {
        height: 100%;
    }
    .quote__container__row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .quote__container__checkbox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.quote__container__checkbox input  {
    width: 15px;
    height: 15px;
}

.quote__container__checkbox {
    display: block;
}

.quote__container button {
    width: 100px;
    margin-top: 20px;
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 16px 28px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
}

.quote__container button:hover {
    background-color: #058757;
}



.lower__styling {
    padding: 0 20px;
}

.lower__styling textarea {
    margin-top: 5px;
    width: 100%;
    height: 150px;
    padding: 10px 15px;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}

.lower__styling textarea:focus {
    outline: none;
    border: 2px solid #4ecdc4;
}